import { Component } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';

@Component({
	selector: 'go-back',
	standalone: true,
	imports: [NzButtonModule, NzIconModule],
	templateUrl: './go-back.component.html',
	styleUrl: './go-back.component.scss'
})
export class GoBackComponent {
	constructor(private _navigationService: NavigationService) {}

	public goBack(): void {
		this._navigationService.goBack();
	}
}
